<template>
  <div class="flex flex-col gap-1">
    <div v-if="props.label || props.optionalText" class="flex row mx-1">
      <label
        v-if="props.label != null"
        class="flex-1 text-tertiary text-sm font-semibold"
        v-text="props.label"
        :for="uuid"
      />
      <span
        class="text-tertiary text-xs"
        v-if="props.optionalText"
        v-text="props.optionalText"
      />
    </div>
    <div
      class="flex items-stretch bg-primary-10 text-sm text-secondary rounded-lg border border-secondary-40 focus-within:border-tertiary-80 overflow-hidden"
      :class="{
        'border-state-error': isValid === 'error',
        'border-state-warning': isValid === 'warning',
        'border-transparent shadow-none pointer-events-none': props.readonly || props.disabled,
        'shadow-small': !props.readonly && !props.disabled
      }"
    >
      <slot name="icon">
        <div
          v-if="prefix != null"
          class="text-secondary bg-secondary-10 p-2 text-sm font-medium flex items-center border-r border-secondary-20 rounded-l-lg"
          v-text="props.prefix"
        />
      </slot>
      <input
        v-if="props.type !== 'textarea'"
        :step="props.step"
        :type="props.type"
        :placeholder="props.placeholder"
        @wheel="onWheelChange"
        :id="uuid"
        :value="modelValue"
        class="text-sm w-full border-transparent rounded-lg border-none focus:outline-none focus:outline-0 focus:ring-0 focus:text-tertiary placeholder:italic placeholder:text-secondary-80 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-primary-10 file:text-primary hover:file:bg-violet-100"
        :readonly="props.readonly"
        :disabled="props.disabled"
        :class="{
          'flex-grow': props.grow,
          'rounded-l-none': props.prefix,
          'rounded-r-none': unsetButton || removeButton,
          'bg-gray-100': props.disabled,
        }"
        @blur="onBlur"
        @input="onInputChange"
        v-bind="$attrs"
      />
      <textarea
        v-else
        :placeholder="props.placeholder"
        :value="modelValue"
        :id="uuid"
        class="text-sm w-full border-transparent rounded-lg border-none focus:outline-none focus:outline-0 focus:ring-0 focus:text-tertiary placeholder:italic placeholder:text-secondary-80"
        :class="{
          'bg-gray-100': props.disabled,
        }"
        :readonly="props.readonly"
        :disabled="props.disabled"
        @blur="onBlur"
        @input="onInputChange"
        v-bind="$attrs"
      ></textarea>
      <button
        v-if="!props.readonly && !props.disabled && (unsetButton || removeButton)"
        type="button"
        class="rounded-r-lg text-secondary-80 bg-secondary-10 hover:text-secondary cursor-pointer text-sm p-2 border-l border-secondary-20 hover:text-primary-80 active:bg-primary-20 active:text-primary disabled:text-secondary-40"
        @click="clear"
        v-text="
          unsetButton
            ? localization(`general.clear`)
            : localization(`general.remove`)
        "
      />
      <button
        v-if="!props.readonly && !props.disabled && addButton"
        type="button"
        class="bg-primary leading-none hover:bg-primary-80 min-w-8 text-primary-10 active:bg-primary-20 active:text-primary disabled:text-secondary-40"
        @click="addButton"
      >
        <i class="material-icons" aria-hidden="true">add</i>
      </button>
      <button
        v-if="!props.readonly && !props.disabled && textButton"
        type="button"
        class="bg-primary text-sm p-2 leading-none hover:bg-primary-80 text-primary-10 active:bg-primary-20 active:text-primary disabled:text-secondary-40"
        @click="onTextButtonClick"
      >
        {{ props.textButton }}
      </button>
    </div>
    <div
      v-if="props.helpText || (isValid !== true && errorMessage)"
      class="text-xs mx-2"
    >
      <span
        v-if="props.helpText"
        class="text-secondary-80"
        v-text="props.helpText"
      />
      <span
        v-if="isValid !== true && errorMessage"
        :class="{
          'text-state-error': isValid === 'error',
          'text-state-warning': isValid === 'warning'
        }"
        class="text-state-error font-medium my-2"
        v-text="props.errorMessage"
      ></span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { UUID } from '@avvoka/shared'
import useDebounce from '../../../../features/_abstract/utils/debounce'
import { computed, inject, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    label?: string
    helpText?: string
    optionalText?: string
    prefix?: string
    min?: number | string
    max?: number | string
    type?: string
    modelValue: any
    placeholder?: any
    readonly?: boolean
    disabled?: boolean
    unsetButton?: boolean
    removeButton?: boolean
    addButton?: any
    textButton?: string
    validation?: (value: unknown) => boolean | 'warning' | 'error'
    errorMessage?: string
    step?: number

    useTempAllocation?: boolean
    // Debounce works only with useTempAllocation
    useDebounce?: boolean
    debounceTime?: number

    inputClass?: string
    grow?: boolean
  }>(),
  {
    type: 'text',
    unsetButton: true,
    addButton: false,
    textButton: '',
    validation: (value: unknown) => true,
    step: 1,
    useTempAllocation: false,
    debounceTime: 500
  }
)

const temp = ref(props.modelValue)
const modelValue = computed({
  get() {
    return props.useTempAllocation ? temp.value : props.modelValue
  },
  set(value) {
    if (props.useTempAllocation) temp.value = value
    else {
      emit('update:modelValue', value)
    }
  }
})

defineExpose({
  tempValue: temp
})

const onBlur = () => {
  if (props.useTempAllocation) {
    emit('update:modelValue', modelValue.value)
  }
}

const onDebounce = () => {
  if (props.useTempAllocation) {
    emit('update:modelValue', modelValue.value)
  }
}

const fnDebounce = useDebounce(onDebounce, props.debounceTime)
watch(modelValue, () => {
  if (props.useTempAllocation && props.useDebounce) {
    fnDebounce()
  }
})

// Update temp value when model value changes from outside
watch(() => props.modelValue, () => {
  if (props.useTempAllocation) {
    temp.value = props.modelValue
  }
})

const clear = () => {
  modelValue.value = null
  emit('update:modelValue', null)
}

const localization =
  inject<VueInjectLocalization>('localization') ?? ((...args: unknown[]) => {})
const emit = defineEmits(['update:modelValue', 'onSearch'])
const onInputChange = (event: Event) => {
  if (!props.readonly && !props.disabled) {
    const value = (event.target as unknown as HTMLInputElement).value
    modelValue.value = value.length === 0 ? '' : value
  }
}

const isValid = computed(() => {
  if (typeof props.validation === 'function') {
    return props.validation(props.modelValue)
  }
  return true
})

const onWheelChange = (event: Event) => {
  if (props.type === 'number') {
    const target = event.target as unknown as HTMLInputElement
    target.blur()
  }
}

const onTextButtonClick = () => {
  if (props.type === 'search') {
    emit('onSearch')
  }
}

const uuid = UUID.new()
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'DInput' })
</script>
